export default [
  {
    image: "codeguard/Automatic-Daily-Backups.svg",
    title: "Automatic Daily Backups",
    imageAlt: "Automatic Daily Backups",
  },
  {
    image: "codeguard/Website-Time-Machine.svg",
    title: "Website Time Machine",
    imageAlt: "Website Time Machine",
  },
  {
    image: "codeguard/WordPress-Plugin-Updates.svg",
    title: "WordPress Plugin Updates",
    imageAlt: "WordPress Plugin Updates",
  },

  {
    image: "codeguard/File-Change-Alert-Monitoring.svg",
    title: "File Change Alert Monitoring",
    imageAlt: "File Change Alert Monitoring",
  },
  {
    image: "codeguard/Malware-Detection-Restore.svg",
    title: "Malware Detection and Restore",
    imageAlt: "Malware Detection and Restore",
  },
];
