import React from "react";
import PropTypes from "prop-types";
export default function tabsContent(props) {
  return (
    <div className="flex justify-between mobile:block">
      <div className="flex justify-items-start mobile:pb-8">
        <div className="text-md text-gray-500 text-left border-r-2 w-32 mobile:w-1/2 mobile:text-center">
          Storage
          <div className="text-2xl text-gray-900 font-semibold mobile:text-xl">
            {props.storage}
          </div>
        </div>
        <p className="text-md text-gray-500 text-left px-10  mobile:w-1/2 mobile:text-center mobile:px-0">
          Price
          <div className="text-2xl  text-gray-900 font-semibold mobile:text-xl">
            ${props.price} USD<span className="font-normal">/mo</span>
          </div>
        </p>
      </div>
      <div className="">
        <a
          href={`https://client.hostylus.com/cart.php?a=add&pid=${props.productID}`}
        >
          <button className=" outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-3  rounded-3xl px-8 border-2 mb-2    uppercase laptop:mt-2">
            Order Now
          </button>
        </a>
      </div>
    </div>
  );
}
tabsContent.propTypes = {
  storage: PropTypes.any.isRequired,
  price: PropTypes.any.isRequired,
  productID: PropTypes.any.isRequired,
};
