import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import Feature from "../components/featureBlock";
import ImageLeft from "../components/imageLeft";
import Image from "../components/imageProcessing";
import Pricing from "../components/getStorgePrice";
import Features from "../DB/codeguard-features";
import FeaturesWithDescription from "../DB/codeguard-features-description";
import AccordionContent from "../DB/codeguard-FAQ";
import Accordion from "../components/accordion";
// import Image from "../components/imageProcessing";
/* eslint react/prop-types: 0 */

function codeguard() {
  return (
    <Layout>
      <SEO
        keywords={[`website backup`, ` WordPress backup plugin`]}
        title="Website Backup In The Cloud, Best WordPress Backup Plugin"
        image={"/hostylus-card-preview.jpg"}
        description="CodeGuard is the fastest and most reliable website backup, monitoring, and restore service available. The cloud based software removes malware from your website, has an automatic WordPress backup plugin, manages website migrations, backups email and also has a full featured API."
      />
      <Landing
        header="Protect your website with daily automated backups"
        desc="Get protection against viruses, hackers and even your own code accidentally breaking your site with CodeGuard Website Backup."
        btnURL="#pricing"
        btnText={"Get Started"}
        image={<Image image={"codeguardLanding.png"} alt={"codegurad"} />}
        hideSecondary_btn={"hidden"}
      />
      <ImageLeft
        image={
          <div className="mx-auto shadow-xl w-3/5">
            <Image
              image={"codeguard-dashboard.jpg"}
              alt={"codeguard-dashboard"}
            />
          </div>
        }
        header={
          <h2 className="mr-20 leading-10	mobile:m-0 laptop:mr-6">
            &quot;Every 0.65 seconds, a new web page is infected with
            malware.&quot;
          </h2>
        }
        desc={
          <div className="w-2/3 mobile:w-full laptop:w-full">
            <p>
              &quot;Protect your site from data loss and corruption, as well as
              against threats from viruses, hackers and malware with Daily
              Automated Website Backups from CodeGuard.
            </p>
            <br></br>
            <p>
              &quot;With CodeGuard Website Backup, your website is backed up
              daily and if disaster strikes, you can restore your site to a
              previous point in time at the click of a button.&quot;
            </p>
          </div>
        }
        hideShopNow={"hidden"}
        hideLearnMore={"hidden"}
      />

			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<div className="features grid grid-cols-5 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-8 laptop:px-6">
					{Features.map((feature, key) => (
						<Feature
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
						/>
					))}
				</div>
			</section>
			<section id="pricing" className=" bg-blue-600">
				<div className="storagePricing max-w-4xl mx-auto text-center py-16 mobile:px-3 mobile:py-8 tablet:px-6 mobile:py-10">
					<h2 className="text-3xl text-white py-8">Choose Backup Storage</h2>
					<Pricing />
				</div>
			</section>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<h2 className="text-4xl text-black font-bold text-center capitalize">
					Features
				</h2>

				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-8 laptop:px-6">
					{FeaturesWithDescription.map((feature, key) => (
						<Feature
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
							desc={feature.description}
						/>
					))}
				</div>
			</section>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl mobile:px-2">
					<h2 className="text-center text-black mb-16 text-4xl font-bold uppercase">
						Frequently Asked Questions
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}

export default codeguard;
