export default [
  {
    title: "What is CodeGuard?",
    content:
      "CodeGuard is a fully automated website backup service that gives you complete protection against data loss and malware.",
  },
  {
    title: "Why do I need CodeGuard?",
    content:
      "CodeGuard provides an independent offsite backup solution for your website along with daily monitoring to ensure your website is online and malware free.",
  },
  {
    title: "How does it work?",
    content:
      "CodeGuard takes daily automated snapshots of your website. Using these snapshots, you can restore your entire site or a specific file to an earlier version at any time.",
  },

  {
    title: "What if I run out of storage?",
    content:
      "Switching plans is easy! You can upgrade and increase your disk storage allowance in just a few simple clicks via our client area.",
  },
  {
    title: "Where are backups stored?",
    content:
      "Backups are stored on Amazon Web Services Simple Storage System which provides market leading resiliance and redundancy for your backups.",
  },
  {
    title: "Are the backups encrypted?",
    content:
      "Yes, backups are stored encrypted using the AES-256 Encryption Standard.",
  },
  {
    title: "Do you backup databases?",
    content:
      "Yes, databases can be backed up also. Database backups are supported for MySQL and MSSQL databases.",
  },

  {
    title: "What is File Change Alert Monitoring?",
    content:
      "CodeGuard can monitor and notify you by email when your website changes to alert you to new threats and malware.",
  },
  {
    title: "What happens if my site gets infected?",
    content:
      "With CodeGuard's daily snapshots, if your website gets attacked, you can restore to a previous uninfected version at any time.",
  },
];
