import React from "react";

import PropTypes from "prop-types";
import Tabs from "react-responsive-tabs";
import Content from "../components/storagePricingContent";
// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
import { graphql, useStaticQuery } from "gatsby";

export default function Product() {
  const data = useStaticQuery(graphql`
    query codeguardQuery {
      allProducts(filter: { productGroupID: { eq: 5 } }) {
        nodes {
          productID
          productGroupID
          name
          monthly
        }
      }
    }
  `);
  const tabs = [];
  data.allProducts.nodes.forEach((product) => {
    tabs.push({
      title: product.name.split(" ")[0],
      content: (
        <Content
          storage={product.name.split(" ")[0]}
          price={product.monthly}
          productID={product.productID}
        />
      ),
    });
  });
  // const tabs = [
  //   {
  //     title: "1GB",
  //     content: <Content storage={data.allProducts.nodes[0].name.split(" ")[0]} price={data.allProducts.nodes[0].monthly} />,
  //   },
  //   {
  //     title: "5GB",
  //     content: <Content storage={5} price={data.allProducts.nodes[1].monthly} />,
  //   },
  //   {
  //     title: "10GB",
  //     content: <Content storage={10} price={data.allProducts.nodes[2].monthly} />,
  //   },
  //   {
  //     title: "25GB",
  //     content: <Content storage={25} price={data.allProducts.nodes[3].monthly} />,
  //   },
  //   {
  //     title: "50GB",
  //     content: <Content storage={50} price={data.allProducts.nodes[4].monthly} />,
  //   },
  //   {
  //     title: "100GB",
  //     content: <Content storage={100} price={data.allProducts.nodes[5].monthly} />,
  //   },
  //   {
  //     title: "200GB",
  //     content: <Content storage={200} price={data.allProducts.nodes[6].monthly} />,
  //   },
  // ];

  function getTabs() {
    return tabs.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      /* Optional parameters */
      key: index,
      tabClassName: "storageTab",
      panelClassName: "storagePanel",
    }));
  }

  return <Tabs items={getTabs()} transformWidth={770} />;
}

Product.propTypes = {
  ID: PropTypes.string,
};
