export default [
  {
    image: "codeguard/Daily-Automatic-Website-Backups.svg",
    title: "Daily Automatic Website Backups",
    description:
      "Secure your website with automated daily backups stored offsite with built-in redundancy.",
    imageAlt: "Automatic Daily Backups",
  },
  {
    image: "codeguard/Unlimited-Files-Databases.svg",
    title: "Unlimited Files & Databases",
    description:
      "Backup an unlimited number of files and databases - you are restricted only by the storage space you use.",
    imageAlt: "Unlimited Files & Databases",
  },
  {
    image: "codeguard/One-Click-Restores.svg",
    title: "One-Click Restores",
    description:
      "A simple restore process makes it easy to rollback a single file or your entire website to a previous version.",
    imageAlt: "One-Click Restores",
  },
  {
    image: "codeguard/Malware-Monitoring.svg",
    title: "Malware Monitoring",
    description:
      "Rest easy knowing CodeGuard is diligently checking your site for changes every day.",
    imageAlt: "Malware Monitoring",
  },
  {
    image: "codeguard/WordPress-Plugin-Updates.svg",
    title: "Automatic WordPress Updates",
    description:
      "Automatically update WordPress and its plugins to keep it secure with auto recovery in case of problems.",
    imageAlt: "Automatic WordPress Updates",
  },
  {
    image: "codeguard/File-Change-Monitoring.svg",
    title: "File Change Monitoring",
    description:
      "Get notified by email anytime something changes within the source code of your site.",
    imageAlt: "File Change Monitoring",
  },
  {
    image: "codeguard/Staging-of-Restores.svg",
    title: "Staging of Restores",
    description:
      "Quickly test any backed up site with simple and automated staging prior to restore.",
    imageAlt: "Staging of Restores",
  },
  {
    image: "codeguard/Email-Backup.svg",
    title: "Email Backup",
    description:
      "Get protection for your emails too as they are backed up as part of your websites files.",
    imageAlt: "Email Backup",
  },
  {
    image: "codeguard/Full-Automation.svg",
    title: "Full Automation",
    description:
      " Completely hands free setup and ongoing backups with automated notifications if things go wrong.",
    imageAlt: "Automatic Daily Backups",
  },
];
